import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';
import { AvailabilitiesService } from '@dialog-eservices-enablement/angular-components';
import { UserEServicesService } from '@dialog-eservices-enablement/angular-components';
import { LearnMoreDialogService } from '@dialog-eservices-enablement/angular-components';
import { LiveChatService } from '@dialog-eservices-enablement/angular-components';
import { AnalyticsService } from './core/services/analytics.service';
import { View } from './shared/models/view';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { RegionDialogService } from './core/services/region-dialog.service';
import { AuthApiService } from '@dialog-eservices-enablement/angular-components';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'dl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  @ViewChild('eserviceAvailabilityError') private eServicesAvailabilityMessage: ElementRef;
  @ViewChild('userEservicesError') private userEservicesMessage: ElementRef;
  @ViewChild('noLearnMoreContent') private learnMoreMessage: ElementRef;
  @ViewChild('requestSuccess') private requestSuccessMessage: ElementRef;
  @ViewChild('requestFail') private requestFailureMessage: ElementRef;
  @ViewChild('liveChatError') private liveChatErrorMessage: ElementRef;
  @ViewChild('favoriteAdded') private favoriteAddedMessage: ElementRef;
  @ViewChild('favoriteRemoved') private favoriteRemovedMessage: ElementRef;
  @ViewChild('favoriteError') private favoriteErrorMessage: ElementRef;
  @ViewChild('changeGroupsError') private changeGroupsError: ElementRef;
  @ViewChild('changeGroupsSuccess') private changeGroupsSuccess: ElementRef;
  @ViewChild('createGroupError') private createGroupError: ElementRef;
  @ViewChild('createGroupSuccess') private createGroupSuccess: ElementRef;
  @ViewChild('deleteGroupError') private deleteGroupError: ElementRef;
  @ViewChild('deleteGroupSuccess') private deleteGroupSuccess: ElementRef;
  @ViewChild('editGroupError') private editGroupError: ElementRef;
  @ViewChild('editGroupSuccess') private editGroupSuccess: ElementRef;
  @ViewChild('getGroupsError') private getGroupsError: ElementRef;
  @ViewChild('getHelpTicketSuccess') private getHelpTicketSuccess: ElementRef;
  @ViewChild('getHelpTicketRequestTypeTechnicalIssue') private getHelpTicketRequestTypeTechnicalIssue: ElementRef;
  @ViewChild('getHelpTicketRequestTypeFeatureRequest') private getHelpTicketRequestTypeFeatureRequest: ElementRef;
  @ViewChild('getHelpTicketRequestTypeOther') private getHelpTicketRequestTypeOther: ElementRef;

  public View = View;
  public view$: Observable<View> = this.breakpointObserver.observe('(min-width: 600px)').pipe(
    map((result: BreakpointState) => result.matches),
    map((isDesktop: boolean) => {
      if (!isDesktop) {
        return View.Mobile;
      } else {
        if (this.authStoreService.isLoggedIn) {
          return View.DesktopLoggedIn;
        } else {
          return View.DesktopLoggedOut;
        }
      }
    })
  );

  public isLoggedIn = this.authStoreService.isLoggedIn;

  public constructor(
    private analyticsService: AnalyticsService,
    private breakpointObserver: BreakpointObserver,
    private authStoreService: AuthStoreService,
    private availabilitiesService: AvailabilitiesService,
    private userEservices: UserEServicesService,
    private learnMoreService: LearnMoreDialogService,
    private liveChatService: LiveChatService,
    private regionDialogService: RegionDialogService,
    private authService:AuthApiService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnInit(): void {

    this.authStoreService.isLoggedIn ? '' : this.authService.loginWithSaml();
    this.analyticsService.init();
    this.regionDialogService.openDialog(true);
    this.getLanguageCode();
    if (this.authStoreService.canOpenLoginDialog()) {
      this.authStoreService.openLoginDialog();
    }
  }
  
  public getLanguageCode(): void {
    localStorage.setItem('languageCode', localStorage.getItem('languageCode') || 'en');
  }

  public ngAfterViewInit(): void {
    this.availabilitiesService.eServiceAvailabilityError = this.eServicesAvailabilityMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.userEservicesError = this.userEservicesMessage.nativeElement.previousSibling.wholeText;
    this.learnMoreService.learnMoreErrorText = this.learnMoreMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.requestAccessSuccessMessage = this.requestSuccessMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.requestAccessErrorMessage = this.requestFailureMessage.nativeElement.previousSibling.wholeText;
    this.liveChatService.liveChatErrorMessage = this.liveChatErrorMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.favoriteAddedMessage = this.favoriteAddedMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.favoriteRemovedMessage = this.favoriteRemovedMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.favoriteErrorMessage = this.favoriteErrorMessage.nativeElement.previousSibling.wholeText;
  }
}
