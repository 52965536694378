<ng-container *ngIf="formResponse | async">
    <section class="register-form">
        <div class="registerHeader ">
            <div class="crossImage-header col-lg-12">
                <img class="crossimage-property mt-2 ms-3 mat-icon-button" (click)="closeRegistrationForm()"  src="assets/cross.png">
            </div>

            <div class="col-lg-12 thanksText message-box">
                <p class=" px-5 pt-4" [innerHTML]="formlabelsData?.title">
                </p>
                <div class="pb-4 head-margin">
                    <span [innerHTML]="formlabelsData?.register_session_for"></span>&nbsp;<span>"{{projectTitle}}"</span>
                </div>
            </div>
        </div>

        <div class="box container">
            <div class="col-lg-12">
                <p class="formHeading px-0">{{formlabelsData?.personal_detail_label}}</p>
            </div>
            <form [formGroup]="registrationForm" class="form-formatting">
                <mat-form-field class="inputbox" appearance="fill" hideRequiredMarker>
                    <mat-label>{{formlabelsData?.register_mail}}</mat-label>
                    <input autofocus type="email" matInput formControlName="email" required
                        placeholder="john.public@email.com" />
                </mat-form-field>
                <mat-form-field class="inputbox" appearance="fill" hideRequiredMarker>
                    <mat-label>{{formlabelsData?.register_first_name}}</mat-label>
                    <input autofocus type="first name" matInput formControlName="first_name" required
                        placeholder="john.public@email.com" />
                </mat-form-field>
                <mat-form-field class="inputbox" appearance="fill" hideRequiredMarker>
                    <mat-label>{{formlabelsData?.register_last_name}}</mat-label>
                    <input autofocus type="last name" matInput formControlName="last_name" required
                        placeholder="john.public@email.com" />
                </mat-form-field>
            </form>
            <div>
                <p class="bottomText" [innerHTML] = "formlabelsData?.event_registration_note"></p>
            </div>
            <div class="btns">
                <span>
                <button mat-button class="cancel col-lg-2 " (click)="closeRegistrationForm()" >{{formlabelsData?.register_cancel_button}}</button>
                </span>
            <span>
                <button mat-raised-button class="reg mrgin col-lg-2"  (click)="saveRegistrationForm()" color="primary">{{formlabelsData?.register_submit_button}}</button>
            </span>
            </div>
        </div>

    </section>
</ng-container>
<!-- <button routerLink="/afterregistration">demo</button> -->