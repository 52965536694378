<ng-container *ngIf="askInfoThankslabel">
    <section class="askinfo-form">
        <div class="askInfoGreeting ">
            <div class="crossImage-header col-lg-12">
                <img class="crossimage-property mat-icon-button mt-2 ms-3" (click)="closeRegistrationForm()" src="assets/cross.png">
            </div>
            <div class="col-lg-12 message-box">
                <p class="thanksText px-5 py-5">{{askInfoThankslabel?.thanks_for_your_interest}}</p>
            </div>
            <div class="col-lg-12">
                <p class="content-property px-5 py-5" [innerHTML] = "askInfoThankslabel.thanks_message_for_question">
                </p>
                <div class="col-lg-12 px-5 py-4">
                    <button mat-raised-button class="askInfoBtn px-2 py-0" (click)="closeRegistrationForm()">{{askInfoThankslabel?.ask_info_close}}</button>
                </div>
            </div>
        </div>
    </section>
</ng-container>