import { LocationStrategy } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';

@Component({
  selector: 'dl-experience-lab',
  templateUrl: './experience-lab.component.html',
  styleUrls: ['./experience-lab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperienceLabComponent implements OnInit {

  public data = {
    banner: 'Its banner',
    coCreationTitle: 'what is Co- creation about',
    coCreationTxt: 'CoCreation Txt',
    openProjectTitle: 'Open projects'
  };
  public isLoggedIn = this.authStoreService.isLoggedIn;
  public projectId: any;
  public currntUrl: any;
  public constructor(private authStoreService: AuthStoreService, private router: Router, private route: ActivatedRoute, private location: LocationStrategy) {

  }


  public ngOnInit(): void {


    // const event_id = this.route.snapshot.queryParamMap.get('prop'),
    //   staticLabelApi = '/api/open-project/label',
    //   projectDetailsApi = '/api/open-projects/details';

    // const event_id2 = this.route.snapshot.queryParamMap.get('props'),
    //   staticLabelApis = '/api/open-project/label',
    //   projectDetailsApis = '/api/open-projects/details';

    //debugger

    // if (event_id == 'backButtons' || event_id2 == 'appLogo') {
    //   this.router.navigate(['/app-enabler']);
    //   localStorage.removeItem('afterSuccessfullSSOLogin');
    //   localStorage.removeItem('comingDirectFromSSOLogin');
    // }
  }
}
