import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { forkJoin, Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'dl-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectComponent implements OnInit {
  //  public projectData = [
  //   {
  //     title: 'Roche Mobile Ordering Event 3',
  //     event_description: 'Numerous manual paper-based activities, long intake of delivered supplies, difficult to manage order and intake process - Does this sound familiar to you? <br />\r\nWith the Roche Mobile Ordering, you can place your orders for reagents and consumables electronically. We are looking for participants to review how the current order intake process is supported by the mobile app.',
  //     event_image: 'https://cep-drupal-dev.roche.com/sites/default/files/2021-08/download%20%282%29_0.jpg',
  //     event_video_url: 'https://www.youtube.com/embed/WovwuOFBUuY',
  //     your_voice_counts: 'Your Voice Counts!',
  //     sign_up_now: 'Sign up now and innovate healthcare together.',
  //     laboratory_size: 'Small to mid-sized',
  //     other_criteria: 'Frequent user of Roche Mobile Ordering',
  //     roles: 'Lab managers and Lab Technicians',
  //     contact_description: '<p>For further questions, changing details of your involvement, or for cancelling your participation, feel free to reach out to your country representative of the “Your Voice” network </p>',
  //     contact_email: 'aaa.bbbbb@roche.com',
  //     contact_type: '“Your Voice” representative in your country',
  //     project_overview: '<p>Many laboratories still order products using time-consuming and error-prone manual processes. Roche Mobile Ordering digitizes and integrates all these processes, allowing labs to order everything from reagents and calibrators to controls, and consumables without using paper product lists, re-typing orders, or sending orders via email or fax.</p>\n\n<p>In this project, we would like to learn about your experience using Roche Mobile Ordering on the delivery intake process; whether and how the current solution fits your lab dynamic, and where it can be improved to support you better in the overall process.</p>',
  //     expected_results: 'Monday, August 30, 2021 - 17:30',
  //     expected_time_frame_dscr: '<p>Please note that the exact date and time of the activity is going to be arranged with you after registration and participation is confirmed.</p>',
  //     expected_time_frame_for_the_acti: 'Sun, 08/29/2021 - 17:30',
  //     what_is_expected_of_me: '1x 60 mins online interview with prototype testing, 2x 30 min Online Follow up Interview',
  //     registration_closes: '<time datetime="2021-08-30T12:00:00Z" class="datetime">Mon, 08/30/2021 - 17:30</time>\n',
  //     what_happen_after_sgnup_dscr: '<p>We review all applications and select candidates per the requirements for the project. To be fair, we’ll try to get on board the ones who have never taken a part in a project before.</p>\n\n<p>Based on your application, if it looks like you’ll be a good fit for the project , a member of the team will connect with you to go through a couple of questions and arrange the proper timing to take part. In case your participation cannot be granted, our team will also inform you about that. If you have any questions on your registration, please get in touch with the contact person for this project (see details below).</p>',
  //     what_happen_after_signedup_title: 'What happens after I signed up?',
  //     what_is_the_schedule_like_title: 'What is the schedule like?',
  //     selected_activity_title: 'Prototype Testing',
  //     what_is_prototype_description: 'A prototype is an early sample, model, or release of a product built to test a concept or process.',
  //     what_kind_of_activities_are_plan: 'Prototype Testing',
  //     event_register_reference: '',
  //     event_register_status: ''
  //   }
  // ];
  //public staticLabels: Array<any>;
  //public projecDetailsResponse: Observable<any>;
  //public projectData: Array<object>;
  public projectDataLabel: Array<object>;
  public event_id: any;
  public projectPageURL: any;
  public projectId: any;

  public staticLabelApi;
  public projectDetailsApi;



  public constructor(private appService: AppService, private route: ActivatedRoute, private router: Router) { }

  public ngOnInit(): void {
    this.event_id = this.route.snapshot.queryParamMap.get('event_id'),
      this.staticLabelApi = '/api/open-project/label',
      this.projectDetailsApi = '/api/open-projects/details';
  }
}
