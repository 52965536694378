<ng-container>
<div  class="container">
    <!-- <div class="video">
      <div class='videoSec'>
        <dl-video-image *ngIf="projectData" [projectData]="projectData" [projectLabels]="staticLabels"></dl-video-image></div>
        <dl-project-title-and-sumarizing [projectData]="projectData" [projectLabels]="staticLabels"></dl-project-title-and-sumarizing>
    </div>
    <dl-projectover *ngIf="projectData" [projectData]="projectData" [projectLabels]="staticLabels"></dl-projectover>
    <dl-what-happends-after-i-singned-up *ngIf="projectData" [projectData]="projectData" [projectLabels]="staticLabels"></dl-what-happends-after-i-singned-up>
    <dl-contact *ngIf="projectData" [projectData]='projectData' [projectLabels]="staticLabels"></dl-contact> -->
<dl-project-title-and-details [event_id]="event_id"></dl-project-title-and-details>
  </div>
  <!-- <dl-project-backend></dl-project-backend> -->
</ng-container>