import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AppService } from './../../../app.service';

@Component({
  selector: 'dl-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent implements OnInit {
  bannerResponse: any;
  public bannerData = {
    co_creation_banner_description: '',
    co_creation_banner_image: '',
    title: ''
  }
  dataRenderFlag: boolean = false;
  public constructor(private appService: AppService) { }

  public ngOnInit(): void {
    const apiUrl = '/api/cocreation/banner';
    this.appService.getApi(apiUrl).subscribe(data => {
      this.bannerData.co_creation_banner_description = data[0].co_creation_banner_description;
      this.bannerData.co_creation_banner_image = data[0].co_creation_banner_image;
      this.bannerData.title = data[0].title;
      this.dataRenderFlag = true
    });
  }
}
