<ng-container *ngIf="formTahnksData">
    <section class="afterregistration-form">
        <div class="registrationGreeting ">
            <div class="crossImage-header col-lg-12">
                <img class="crossimage-property mat-icon-button mt-2 ms-3" (click)="closeRegistrationForm()" src="assets/cross.png">
            </div>
           
            <div class="col-lg-12 message-box">
                <div><img class="thanks-image" src={{formTahnksData?.register_confirmation_image}} alt="image"></div>
                <p class="thanksText px-5 py-3">{{formTahnksData?.reg_thank_you_for_your_interest}}</p>
            </div>
            <div class="col-lg-12">
                <p class="content-property px-5 py-0" [innerHTML] = "formTahnksData?.reg_thank_you_message"></p>
                <div class="col-lg-12 px-5 py-4">
                    <button mat-raised-button class="regBtn px-2 py-0" (click)="closeRegistrationForm()">{{formTahnksData?.reg_close}}</button>
                </div>

            </div>
        </div>
    </section>
</ng-container>