import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ProjectComponent } from './project.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { ProjectTitleAndDetailsComponent } from './project-title-and-details/project-title-and-details.component';
import { BoldPipe } from './project-title-and-details/bold.pipe';
import {MatIconModule} from '@angular/material/icon';
import {OneExpandableModule} from '@one/angular-kit/layout';
import {MatDividerModule} from '@angular/material/divider';
@NgModule({
  declarations: [ProjectComponent, ProjectTitleAndDetailsComponent, BoldPipe],
  exports: [],
  imports: [CommonModule,OneExpandableModule,MatDividerModule, RouterModule,MatIconModule, MatCardModule, MatButtonModule]
})
export class ProjectModule {}
