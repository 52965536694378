import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { BannerComponent } from './banner/banner.component';
import { CoCreationComponent } from './co-creation/co-creation.component';
import { ExperienceLabComponent } from './experience-lab.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CollaborateUsComponent } from './collaborate-us/collaborate-us.component';
import { MatButtonModule } from '@angular/material/button';
import { OpenProjectComponent } from './open-project/open-project.component';

import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OneSelectDropdownModule } from '@one/angular-kit/form';
import { MatSelectModule } from '@angular/material/select';
import { OneSnackBarModule } from '@one/angular-kit/modal';

@NgModule({
  declarations: [BannerComponent, CoCreationComponent, ExperienceLabComponent, CollaborateUsComponent, OpenProjectComponent, HowItWorksComponent],
  exports: [BannerComponent, CoCreationComponent, ExperienceLabComponent],
  imports: [MatStepperModule, CommonModule, OneSnackBarModule, MatGridListModule, MatCardModule, MatIconModule, MatButtonModule, OneSelectDropdownModule, FormsModule, ReactiveFormsModule, MatSelectModule]
})
export class ExperienceLabModule {}
