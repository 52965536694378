<ng-container *ngIf="dataRenderFlag && dataRenderFlag2 && dataRenderFlag3">
  <section class="open-project">
    <div class="content">
      <mat-card>
        <mat-card-title >
          {{staticLabels.title}}
        </mat-card-title>
        <!-- <mat-form-field class="categories-select" >
          <mat-label>{{filteredProjectOptions[0]}}</mat-label>
          <mat-select oneSelect [formControl]="selectFormControl" (selectionChange)="filterProjectData($event.value)" appearance="outline" size="small">
            <mat-option value="{{categoryItem}}" *ngFor="let categoryItem of filteredProjectOptions | slice:1;">
              {{categoryItem}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field class="categories-select1" floatLabel="never" appearance="fill" style="display: none;" >
          <mat-label>{{filteredProjectOptions[0]}}</mat-label>
          <mat-select oneSelect [formControl]="selectFormControl" (selectionChange)="filterProjectData($event.value)" appearance="outline" >
            <mat-option class="ss" value="{{categoryItem}}" *ngFor="let categoryItem of filteredProjectOptions | slice:1;">
              {{categoryItem}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field appearance="fill">
          <mat-label>Favorite food</mat-label>
          <mat-select [(ngModel)]="selectedValue" name="food">
            <mat-option *ngFor="let food of foods" [value]="food.value">
              {{food.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <mat-card-subtitle [innerHTML]="staticLabels.open_projects_description">
        </mat-card-subtitle>
        <mat-card-content>
          <div class="project-listing-title" *ngIf="projectList?.length !=0; else errorMessage">
            
            <div class="project-list" *ngFor="let project of projects">
              <div class="imgHolder" style="position: relative;">
                <img mat-card-image *ngIf = "project.event_image_url != '' " src="{{project.event_image_url}}" alt="project portfolio">
                <img mat-card-image *ngIf = "project.event_image_url == '' " src="{{staticLabels.default_image_static}}" alt="project portfolio">
                <span [ngClass]="labelClassBind(project.event_register_status)" [innerHTML]="getProtoType(project)">
                </span>
              </div>
              <div class="project-content">
                <span class="time-stamp">{{project.registration_closes}}</span>
                <span class="project-title">
                  {{project.title}}
                </span>
                <span class="project-short-descp" [innerHTML]= "project.event_description">
                </span>
              </div>
            <button color="primary" class="read-more" mat-flat-button (click)="redirect(project.nid)">{{staticLabels.read_more_static}}</button>
            </div>
          </div>
          <ng-template #errorMessage>
            <span class="no-open-pro-msg">
              {{staticLabels.messages_for_no_projects}}
            </span>
          </ng-template>
        </mat-card-content>
        <!-- <mat-card-actions *ngIf="projectList?.length">
          <a href="#" (click)="!!showMore()" class="show-more-link" *ngIf="projects?.length < filteredData.length"> {{staticLabels.show_more_project}}</a>
          <a href="#" (click)="!!showLess()" class="show-less-link" *ngIf="projects?.length > 3">{{staticLabels.show_less_project}}</a>
        </mat-card-actions> -->
      </mat-card>
    </div>
  </section>
</ng-container>