import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../app.service';


@Component({
  selector: 'dl-askinfogreeting',
  templateUrl: './askinfogreeting.component.html',
  styleUrls: ['./askinfogreeting.component.scss']
})
export class AskinfogreetingComponent implements OnInit,OnDestroy {
  public event_id: any;
  public projectTitle: any;
  public askInfoThankslabel:any;
  public constructor(private renderer: Renderer2, private router: Router,private route: ActivatedRoute,public appService: AppService,private changeDetectorRef: ChangeDetectorRef) {
    this.renderer.addClass(document.body, 'local-notification-config3');
  }
  public ngOnDestroy() {
    this.renderer.removeClass(document.body, 'local-notification-config3');
  }

  ngOnInit(): void {
    this.event_id = this.route.snapshot.queryParamMap.get('event_id');
    this.projectTitle = this.route.snapshot.queryParamMap.get('title');
    var askInfoLabelAPI = "/api/ask-info-labels"

    this.appService.getApi(askInfoLabelAPI)
      .subscribe(res => {
        if (res) {
          this.askInfoThankslabel = res[0];
          this.changeDetectorRef.detectChanges();
        }
      });
  }
  public redirect(): void {
    this.router.navigate(['/project']);
  }

  public closeRegistrationForm(): void {
    this.router.navigate(['/project'], { queryParams: { event_id: this.event_id } });
  }

}
