import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegistrationRoutingModule } from './registration-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import { RegistrationFormComponent } from '../registration-form/registration-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { OneSnackBarModule } from '@one/angular-kit/modal';
import { AfterregistrationComponent } from './afterregistration/afterregistration.component';
import { Registrationform2Component } from './registrationform2/registrationform2.component';

@NgModule({
  declarations: [AfterregistrationComponent, Registrationform2Component],
  imports: [CommonModule, RegistrationRoutingModule, MatIconModule, MatInputModule, MatButtonModule, MatIconModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, OneSnackBarModule]
})
export class RegistrationModule {}
