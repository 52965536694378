import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneSnackBarModule } from '@one/angular-kit/modal';
import { LearnMoreDialogModule } from '@dialog-eservices-enablement/angular-components';
import { RequestAccessDialogModule } from '@dialog-eservices-enablement/angular-components';
import { FeatureFlagModule } from '@dialog-eservices-enablement/angular-components';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CookieService } from 'ngx-cookie-service';
import { AngularBoilerplateComponent } from './components/angular-boilerplate/angular-boilerplate.component';

import { OneSelectDropdownModule } from '@one/angular-kit/form';
import { MatSelectModule } from '@angular/material/select';

import { ReactiveFormsModule } from '@angular/forms';

import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [AngularBoilerplateComponent],
  exports: [AngularBoilerplateComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule, OneSnackBarModule, LearnMoreDialogModule, RequestAccessDialogModule, FeatureFlagModule, OneSelectDropdownModule, MatSelectModule, ReactiveFormsModule, MatTooltipModule, MatMenuModule, MatButtonModule],
  providers: [CookieService]
})
export class SampleFeatureModule {}
