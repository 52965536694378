import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';
//import { EnvironmentInterface } from '@dialog-eservices-enablement/angular-components';
import { EnvironmentsInterface} from './core/models/environments.interface';
import { ENVIRONMENT } from './core/models/environment.token';
import { projectDetails, projectLabels } from './features/project/project-models';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private headerOptions: object;
  private headerOptionsLabel:object;
  private postheaderOptions: object;
  private keyId: string;
  private baseUrl: string;
  // public staticLabels: Array<object>;
  // public projectData: object;
  public projectData: projectDetails = new projectDetails();
  // public detailsData: object;
  private dataLabel2= new BehaviorSubject<any>(null);
  public data$ =this.dataLabel2.asObservable();
  // public isNoData = false;
  private dataDetails= new BehaviorSubject<any>(null);
  public detailsData$ =this.dataDetails.asObservable();

  // public projectData: object;
  // drupApiUrl = 'https://cep-test-drupal.roche.com';
  // drupKey= '2022cf542f0805933dbd236f500db4fa';

  public constructor(@Inject(ENVIRONMENT) private environment: EnvironmentsInterface, private http: HttpClient, private authStoreService: AuthStoreService) {
    //this.domainSetUp();
    this.headerSetUp();
  }

  // public domainSetUp(): void {
  //   const domainUrl = window.location.hostname;

  //   switch (domainUrl) {
  //     case 'cep-angular-dev.roche.com':
  //       this.keyId = 'c85936d6515d10fe8f0e51b711bde96d';
  //       this.baseUrl = 'https://cep-drupal-dev.roche.com';
  //       break;
  //     case 'cep-angular-test.roche.com':
  //       this.keyId = '3f536f00228ca29b5792dce0b49cd9ad';
  //       this.baseUrl = 'https://cep-drupal-test.roche.com';
  //       break;
  //     case 'cep-angular.roche.com':
  //         this.keyId = '4a7d6f6a24644a5f033d80fa8d7c70e6';
  //         this.baseUrl = 'https://cep-drupal.roche.com';
  //         break;
  //     case 'localhost':
  //       this.keyId = 'c85936d6515d10fe8f0e51b711bde96d';
  //       this.baseUrl = 'https://cep-drupal-test.roche.com';
  //       break;
  //   }
  // }

  public headerSetUp(): void {
    const emailId = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.email : '';
    
    this.headerOptions = {
      headers: { 'Content-Type': 'application/json', 'api-key': this.environment.drupKey },
      params: { _format: 'json', langcode: localStorage.getItem('languageCode') || 'en', email: emailId}
    };

    this.postheaderOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { _format: 'json', langcode: localStorage.getItem('languageCode') || 'en', email: emailId }
    };

    this.headerOptionsLabel = {
      headers: { 'Content-Type': 'application/json', 'api-key': this.environment.drupKey },
      params: { _format: 'json', langcode: localStorage.getItem('languageCode') || 'en', email: emailId}
    };
  }

  public getApi(url: string, event_id?: string , country?: string): Observable<any> {
    
    const baseUrl = this.environment.drupApiUrl + url;

    if(country){
      this.headerOptions['params'].country = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.countryCode : '';
    }
    if (event_id) {
      this.headerOptions['params'].event_id = event_id;
    }
     return this.http.get(baseUrl, this.headerOptions)
  }

  public getApi2(url: string, event_id?: string , country?: string, langLabel?: string) {

    const emailId = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.email : '';
    // event_id = '718';
    this.headerOptions = {
      headers: { 'Content-Type': 'application/json', 'api-key': this.environment.drupKey },
      params: { _format: 'json', langcode: langLabel, email: emailId}
    };
    const baseUrl = this.environment.drupApiUrl + url;

    if(country){
      this.headerOptions['params'].country = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.countryCode : '';
    }
    if (event_id) {
      this.headerOptions['params'].event_id = event_id;
    }
    this.http.get(baseUrl, this.headerOptions).subscribe(res =>{
      this.dataDetails.next(res);
     });
    //  return this.http.get(baseUrl, this.headerOptions)
  }
 

  public postApi(url: string, body: any, event_id?: string): Observable<any> {
    const baseUrl = this.environment.drupApiUrl + url;

    if (event_id) {
      this.postheaderOptions['params'].event_id = event_id;
    }
    return this.http.post(baseUrl, body, this.postheaderOptions);
  }

  public getProjectLabel(url: string): Observable<any> {
    const baseUrl = this.environment.drupApiUrl + url;

    return this.http.get(baseUrl, this.headerOptionsLabel);
  }

  getProjectLabel2(url: string, langLabel) {
    const baseUrl = this.environment.drupApiUrl + url;
    const emailId = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.email : '';
   
    this.headerOptionsLabel = {
      headers: { 'Content-Type': 'application/json', 'api-key': this.environment.drupKey },
      params: { _format: 'json', langcode: langLabel, email: emailId}
    };

     this.http.get(baseUrl, this.headerOptionsLabel).subscribe(res =>{
      this.dataLabel2.next(res);
     })
  }

  public getDetailsChange(res) {
    debugger;
        // this.dateFormating = '';
        this.projectData.activity_schedule = res[0].activity_schedule;
          this.projectData.event_description = res[0].event_description;
          this.projectData.event_image_url = res[0].event_image_url;
          this.projectData.title = res[0].title;
          this.projectData.expected_time_frame_for_the_acti =  res[0].appService.expected_time_frame_for_the_acti;
          this.projectData.incentive = res[0].incentive;
          this.projectData.language = res[0].language;
          this.projectData.nid = res[0].nid;
          this.projectData.participating_study = res[0].participating_study;
          this.projectData.session_duration = res[0].session_duration;
          this.projectData.title = res[0].title;
          this.projectData.what_kind_of_activities_are_plan = res[0].what_kind_of_activities_are_plan;
          this.projectData.roles = res[0].roles;
          this.projectData.faq = res[0].faq;
          this.projectData.customer_benefit_2 = res[0].customer_benefit_2;
          this.projectData.customer_benefit_2_image = res[0].customer_benefit_2_image;
          this.projectData.customer_benefit_1 = res[0].customer_benefit_1;
          this.projectData.customer_benefit_1_image = res[0].customer_benefit_1_image;
          this.projectData.compensation_details = res[0].compensation_details;
          this.projectData.compensation_description = res[0].compensation_description;
          this.projectData.compensation_image = res[0].compensation_image;
          this.projectData.compensation_eligible = res[0].compensation_eligible;
          this.projectData.roche_signature = res[0].roche_signature;
          this.projectData.project_date = res[0].project_date;
          this.projectData.project_availability_for_country = res[0].project_availability_for_country;
        // this.appService.projectData = res[0]; 
  }
}
