<div class="parentDiv" *ngIf="flag1 && flag2">
    <div class="projectdetails-section">
        <div class="row">
            <div class="col-lg-4 projectdetails-leftsection mt-4">
                <p class="projectLabelProperty">{{staticLabels.improve_the_experience}}</p>
                <p class="projectTitleProperty">{{projectData.title}}</p>
                <div class="row">
                    <p class="projectSpots col" *ngIf="projectData.project_availability_for_country == 'avail_mode' ">
                        <span class="spotseclipse col-0"> &#11044;</span>
                        {{staticLabels.more_spots_are_available}}
                        
                    </p>
                    <p class="noprojectSpots col" *ngIf="projectData.project_availability_for_country == 'view_mode'">
                        <span class="nospotseclipse col-0"> &#11044;</span>
                        {{staticLabels.no_spots_are_available}}
                    </p>
                </div>

                <div class="mt-3 mb-3">
                    <button mat-raised-button class="signupbutton"
                        [disabled]="projectData.event_register_status == 'Registered' || projectData.project_availability_for_country == 'view_mode'"
                        (click)="redirect(projectData['title'], projectData['nid_1'])"
                        [ngClass]="{'applyBtnFadout': projectData.event_register_status == 'Registered' || projectData.project_availability_for_country == 'view_mode'}"
                        [innerHTML]="projectData.event_register_status == 'Registered' ? 'You signed up' : staticLabels.register_button_label"></button> 
                </div>
                <p class="cocreation-header mt-4" [innerHTML]="staticLabels.about_the_co_creation"></p>
                <div>
                    <div class="row">
                        <div class="col-1">
                            <img src={{staticLabels.profile_image}} alt="image">
                        </div>
                        <p class="col cocreation-text"
                            [innerHTML]=" staticLabels.profiles + ' '  + projectData.roles | bold">
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-1">
                            <img src={{staticLabels.activity_image}} alt="image">
                        </div>
                        <p class="col cocreation-text">
                            {{staticLabels.project_activity}}&nbsp;<b>{{projectData.activity_schedule}}
                                {{projectData.what_kind_of_activities_are_plan}}</b></p>
                    </div>
                    <div class="row">
                        <div class="col-1">
                            <img src={{staticLabels.duration_image}} alt="image">
                        </div>
                        <p class="col cocreation-text">
                            {{staticLabels.project_duration}}&nbsp;<b>{{staticLabels.max_time_per_session}}
                                {{projectData.session_duration}} </b></p>
                    </div>
                    <div class="row">
                        <div class="col-1">
                            <img src={{staticLabels.time_frame_image}} alt="image">
                        </div>
                        <p class="col cocreation-text">
                            {{staticLabels.project_timeframe}}&nbsp;<b>Until {{projectData.project_date}}</b>
                        </p>
                    </div>
                    <!-- <div class="row" *ngIf="projectData.incentive != '' ">
                        <div class="col-1">
                            <img src={{staticLabels.incentive_image}} alt="image">
                        </div>
                        <p class="col cocreation-text"
                            [innerHTML]="staticLabels.project_incentive + ' '  + projectData.incentive | bold">
                        </p>
                    </div> -->
                    <div class="row">
                        <div class="col-1">
                            <img src={{staticLabels.language_images}} alt="image">
                        </div>
                        <p class="col cocreation-text"
                            [innerHTML]="staticLabels.project_language + ' '  + projectData.language | bold">
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 projectdetails-rightsection mt-4">
                <div>
                    <img class="image-property" src={{projectData.event_image_url}} alt="image">
                </div>
                <p class="mt-4 desc-title">{{staticLabels.why_it_matters}}
                </p>
                <p class="event-descr" [innerHTML]="projectData.event_description"></p>
                <p class="signature" [innerHTML]="projectData.roche_signature"></p>
            </div>
        </div>
    </div>

    <div class="projectjoining-section">
        <p class="mt-4 join-title">{{staticLabels.what_you_will_get_by_joining}}</p>
        <div class="row mt-4 " [ngClass]="{'conditional-margin': projectData.compensation_eligible == '' }">
            <div class="col-lg-4 col-md-4  col-xs-12">
                <div>
                    <img src={{projectData.customer_benefit_1_image}} alt="image">
                </div>

                <div>
                    <p class="joiningdesc-property mt-4"
                        [ngClass]="{'conditional-width': projectData.compensation_eligible == '' }"
                        [innerHTML]="projectData.customer_benefit_1"> </p>
                </div>

            </div>
            <div class="col-lg-4 col-md-4  col-xs-12 conditional-margin1"
                [ngClass]="{'conditional-width2': projectData.compensation_eligible == '' }">
                <div>
                    <img src={{projectData.customer_benefit_2_image}} alt="image">
                </div>

                <div>
                    <p class="joiningdesc-property-custom mt-4"
                        [ngClass]="{'conditional-width': projectData.compensation_eligible == '' }"
                        [innerHTML]="projectData.customer_benefit_2"> </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4  col-xs-12" *ngIf="projectData.compensation_eligible != '' ">
                <div>
                    <img src={{projectData.compensation_image}} alt="image">
                </div>

                <div>
                    <p class="joiningdesc-property mt-4" [innerHTML]="projectData.compensation_details"> </p>
                    <p class="compenstaion-desc" [innerHTML]="projectData.compensation_description"> </p>
                </div>


            </div>
        </div>
    </div>

    <div class="participation-section">
        <p class="mt-4 pt-4 ms-3 participation-title">{{staticLabels.what_happen_after_you_apply}}</p>
        <div class="row mt-5 pb-5 mobile-marginparticipation">
            <div class="col-lg-4 col-md-4  col-xs-12 firstcol-margin">
                <div class="eclipse1">
                    <p class="eclipseText">1</p>
                </div>
                <div>
                    <p class="eclipseContent mt-4" [innerHTML]="staticLabels.review_all_application"></p>
                    <p class="review-desc" [innerHTML]="staticLabels.review_all_application_descripti"></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4  col-xs-12 mobile-margin2">
                <div class="eclipse2">
                    <p class="eclipseText">2</p>
                </div>
                <div>
                    <p class="eclipseContent mt-4" [innerHTML]="staticLabels.roche_person_reach_you"></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-12 mobile-margin3">
                <div class="eclipse3">
                    <p class="eclipseText">3</p>
                </div>
                <div>
                    <p class="eclipseContent3 mt-4" [innerHTML]="staticLabels.study_sessions">
                </div>
            </div>
        </div>
    </div>

    <div class="faq-section">
        <p class="mt-4 faq-title" [innerHTML]="staticLabels.faq_title"></p>
        <div class="pt-4">
            <div *ngFor="let data of faqArray; let i = index" class="faq-formatting ">
                <one-expandable appearance="squared" [disabled]="disabled">
                    <div one-expandable-title [innerHTML]="data.question"></div>
                    <div one-expandable-content>
                        <p [innerHTML]="data.answer"></p>
                    </div>
                </one-expandable>
                <mat-divider *ngIf='i != faqArrayLength-1'></mat-divider>
            </div>
        </div>
    </div>

    <div class="footer-section">
        <div class="row pt-5 pb-2">
            <div class="col-5 footertext">
                <p [innerHTML]="staticLabels.footer_heading"></p>
            </div>
            <div class="col-5 mt-4 ml-0">
                <div class="row">
                    <div class="col">
                        <button mat-raised-button class="askInfoBottom" [innerHTML]="staticLabels.ask_info"
                            (click)="redirectAskInfoForm(projectData['title'], projectData['nid_1'])"></button>
                    </div>
                    <div class="col">
                        <button mat-raised-button class="signupbutton"
                            [disabled]="projectData.event_register_status == 'Registered' || projectData.project_availability_for_country == 'view_mode'"
                            (click)="redirect(projectData['title'], projectData['nid_1'])"
                            [ngClass]="{'applyBtnFadout': projectData.event_register_status == 'Registered' || projectData.project_availability_for_country == 'view_mode'}"
                            [innerHTML]="projectData.event_register_status == 'Registered' ? 'You signed up' : staticLabels.register_button_label"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="no-details" *ngIf="flag3">
    <div class="pt-3 pb-3">
        <mat-card>
            <p class="text-center"><strong>This Project is not published in your country.</strong></p>
        </mat-card>
    </div>
</div>