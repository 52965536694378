import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { Observable } from 'rxjs';
import { AppService } from '../../../app.service';
@Component({
  selector: 'dl-registrationform2',
  templateUrl: './registrationform2.component.html',
  styleUrls: ['./registrationform2.component.scss'],
})
export class Registrationform2Component implements OnInit, OnDestroy{
  public registrationForm = new FormGroup({
    email: new FormControl({ value: '', disabled: true }),
    first_name: new FormControl({ value: '', disabled: true }),
    last_name: new FormControl({ value: '', disabled: true })
  });
  
  public formData: any;
  public formResponse: Observable<any>;
  public formlabelsData: any;
  public event_id: any;
  public projectTitle: string = '';
  public isDisabled: boolean = false;
  public constructor(private appService: AppService, private authStoreService: AuthStoreService, private route: ActivatedRoute,private renderer: Renderer2, private router: Router, private readonly oneSnackBar?: OneSnackBarService) {
    this.renderer.addClass(document.body, 'local-notification-config');
  }
  public ngOnDestroy() {
    this.renderer.removeClass(document.body, 'local-notification-config');
  }

  public closeRegistrationForm(): void {
    this.router.navigate(['/project'], { queryParams: { event_id: this.event_id}});
  }

  public saveRegistrationForm(): void {

    const registeruser = "/api/register-user-id";
    const registrationformevent = "/api/open-project-register-form-value";
    const projectupdate = "/api/open-project-email-update";
    const success = "/api/register-success-message";
    let errorResponse = false;
    // add api call here
    let data = {};
    data = {'email':this.registrationForm.get('email').value};
    let body = {'data':data};

    this.appService.postApi(registeruser, body).subscribe(res => {
      this.isDisabled = true;
    }, error => {
    }, () => {
     // this.router.navigate(['/project'], { queryParams: { event_id: this.event_id}});
      //this.oneSnackBar.open(`Registration successfull for the project ${this.projectTitle}`, OneSnackBarType.SUCCESS, '', { duration: 5000 });
    });

    data = {};
    data = this.registrationForm.value;
    data['country_name'] =  this.authStoreService.userProfile.countryCode;
    data['event_name'] = this.route.snapshot.queryParamMap.get('title');
    data['event_id'] = this.event_id;
    body = {'data':data};
    this.appService.postApi(registrationformevent, body, this.event_id).subscribe(res => {
      }, error => {
    });

    this.appService.postApi(projectupdate, body, this.event_id).subscribe(res => {
      data = {};
      data['event_id'] = this.event_id;
      data['email'] = this.registrationForm.get('email').value;
      body = {'data':data} 
      
      this.router.navigate(['/afterregistration'],{ queryParams: { event_id: this.event_id,title: this.projectTitle} });
        //this.router.navigate(['/project'], { queryParams: { event_id: this.event_id}});
      
    });
  }

  public checkRegBtnProp(): void {
    if (this.appService.projectData) {
      this.isDisabled = this.appService.projectData['event_register_status'] !== 'not registered';
    }
  }
  ngOnInit(): void {
    this.checkRegBtnProp();
    const apiUrl  =  '/api/register/label'
    this.event_id = this.route.snapshot.queryParamMap.get('event_id');
    this.projectTitle = this.route.snapshot.queryParamMap.get('title');
    this.formResponse = this.appService.getApi(apiUrl);

    this.formResponse.subscribe(data => {
      this.formlabelsData = data[0];
      console.log(this.formlabelsData);
      this.formData = this.authStoreService.userProfile;
      if (this.formData) {
        
        this.formData.givenName ? this.registrationForm.get('first_name').setValue(this.formData.givenName) : null;
        this.formData.familyName ? this.registrationForm.get('last_name').setValue(this.formData.familyName) : null;
        this.formData.email ? this.registrationForm.get('email').setValue(this.formData.email) : null;

      }
    });
  }
  public redirect(): void {
    this.router.navigate(['/project']);
  }

  public test(){
    const success = "/api/register-success-message";
    this.appService.getApi(success).subscribe(res => {
    
  });
  }
}
