<ng-container *ngIf="askInfolabel">
    <section class="register-form">
        <div class="registerHeader ">
            <div class="crossImage-header col-lg-12">
                <img class="crossimage-property mat-icon-button mt-2 ms-3 " src="assets/cross.png"
                    (click)="closeRegistrationForm()">
            </div>

            <div class="col-lg-12 message-box">
                <p class="thanksText px-5 pt-4" [innerHTML]="askInfolabel?.ask_title_info">
                </p>
                <div class="thanksText pb-4">
                    <span [innerHTML]="askInfolabel?.session_for"></span>&nbsp;<span>"{{projectTitle}}"</span>
                </div>
            </div>
        </div>

        <div class="infobox col-lg-12 container mt-5 ">
            <form [formGroup]="askInfoForm">
                <mat-form-field class="inputbox" appearance="fill" hideRequiredMarker>
                    <mat-label>{{askInfolabel?.ask_comments_and_questions}}</mat-label>
                    <textarea #infotext cdkTextareaAutosize cdkAutosizeMinRows="8" autofocus matInput required
                        placeholder="Type your question here..." formControlName="question">
                     </textarea>

                    <mat-icon (click)="deleteQuestionValue()">close</mat-icon>

                </mat-form-field>
                <div><p class="ask-disclaimer">{{askInfolabel?.ask_disclaimer}}</p></div>
                <div class="row btn-div">
                    <button mat-button class="cancel col-lg-2"
                        (click)="closeRegistrationForm()">{{askInfolabel?.ask_cancel}}</button>


                    <button mat-raised-button class="reg col-lg-2" color="primary"
                        (click)="saveAskedQuestion()">{{askInfolabel?.ask_submit}}</button>
                </div>
            </form>



        </div>

    </section>
</ng-container>