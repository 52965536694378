import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';
import { AppService } from '../../../app.service';

@Component({
  selector: 'dl-askinfoform',
  templateUrl: './askinfoform.component.html',
  styleUrls: ['./askinfoform.component.scss']
})
export class AskinfoformComponent implements OnInit, OnDestroy {

  public askInfoForm = new FormGroup({
    question: new FormControl()
  });


  public event_id: any;
  public projectTitle: any;
  public askInfolabel: any
  public emailId: any;
  public country: any;
  public name: any;


  @ViewChild('infotext') set refField(infotext){if (!infotext) return;infotext.nativeElement.focus()};

  constructor(private authStoreService: AuthStoreService, private renderer: Renderer2, private router: Router, public appService: AppService, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) {
    this.renderer.addClass(document.body, 'local-notification-config2');
  }


  public ngOnDestroy() {
    this.renderer.removeClass(document.body, 'local-notification-config2');

  }

  ngOnInit(): void {
    this.event_id = this.route.snapshot.queryParamMap.get('event_id');
    this.projectTitle = this.route.snapshot.queryParamMap.get('title');
    this.emailId = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.email : '';
    this.country = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.countryCode : '';
    this.name = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.name : '';
    var askInfoLabelAPI = "/api/ask-info-labels"

    this.appService.getApi(askInfoLabelAPI)
      .subscribe(res => {
        if (res) {
          this.askInfolabel = res[0];
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  public saveAskedQuestion(): void {
    const registeruser = "/api/register-user-id";
    const askInfoformevent = "/api/open-project-ask-info-questions";
    const projectAskinfoupdate = "/api/open-project-ask-info-email-update";
    // let errorResponse = false;

    let data = {};
    data = { 'email': this.emailId };
    let body = { 'data': data };


    this.appService.postApi(registeruser, body).subscribe(res => {

      // this.isDisabled = true;
    }, error => {
    }, () => { });


    data = {};
    var questionValue = this.askInfoForm.controls.question.value.trim();

    if (questionValue != "") {
      data['ask_info_email'] = this.emailId;
      data['ask_info_event_name'] = this.projectTitle;
      data['ask_info_country_name'] = this.country;
      data['ask_info_questions'] = questionValue;
      data['ask_info_user_name'] = this.name;
      data['ask_info_event_id'] = this.event_id; 
      data['ask_info_lan_code'] = localStorage.getItem('languageCode');
      body = { 'data': data };

      this.appService.postApi(askInfoformevent, body).subscribe(res => {
        this.askInfoForm.reset();
      }, error => {
      });
    }

    data = {};

    data['event_id'] = this.event_id;
    data['email'] = this.emailId;

    this.appService.postApi(projectAskinfoupdate, body).subscribe(res => {
      if (res.status == 200) {
        this.router.navigate(['/askinfogreeting'], { queryParams: { event_id: this.event_id, title: this.projectTitle } });
      }
    }
      , error => {
      })
  }

  public closeRegistrationForm(): void {
    this.router.navigate(['/project'], { queryParams: { event_id: this.event_id } });
  }

  public deleteQuestionValue() {
    this.askInfoForm.reset();
  }

  // public value = "";


}
