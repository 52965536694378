import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslatedBundleGuard } from '@dialog-eservices-enablement/angular-components';
import { AskinfoformComponent } from './features/ask-info/askinfoform/askinfoform.component';
import { AskinfogreetingComponent } from './features/ask-info/askinfogreeting/askinfogreeting.component';
import { ExperienceLabComponent } from './features/experience-lab/experience-lab.component';
import { ProjectComponent } from './features/project/project.component';
import { AfterregistrationComponent } from './features/registration/afterregistration/afterregistration.component';
import { Registrationform2Component } from './features/registration/registrationform2/registrationform2.component';
//import { AngularBoilerplateComponent } from './features/sample-feature/components/angular-boilerplate/angular-boilerplate.component';

export const appRouterModule: Routes = [
  {
    path: '',
    redirectTo: '/app-enabler',
    pathMatch: 'full'
  },
  {
    path: 'app-enabler',
    component: ExperienceLabComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'project',
    component: ProjectComponent,
    data: { trackWithAnalytics: true }
  },
  {
    path: 'askinfoform',
    component: AskinfoformComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'askinfogreeting',
    component: AskinfogreetingComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'afterregistration',
    component: AfterregistrationComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'registerform2',
    component: Registrationform2Component,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: '**',
    redirectTo: '/app-enabler',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRouterModule, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRouterModule {}
