import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'bold'
})
export class BoldPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: string): string {

    var valueToModify1 = value.split(' ')[0];
    var valueToModify = value.split(':')[1];
    // const regex = new RegExp(/([*])(?:(?=(\\?))\2.)*?\1/g, 'gi');
    //return value.replace(regex, (match) => `<strong>${match.replace(/\*/g,'')}</strong>`);
    return valueToModify1 + '<b>' + valueToModify + '</b>';
  }

}
