export class projectLabels{
    activity_image:string =  "";
    ask_info:string =  "";
    back_to_main_page:string = "";
    duration_image:string = "";
    healthcare_image:string =  "";
    healthcare_text:string =  "";
    improve_the_experience:string =  "";
    incentive_image:string =  "";
    language_images:string =  "";
    learn_about_roche_image:string =  "";
    learn_about_roche_text:string =  "";
    max_time_per_session:string =  "";
    more_spots_are_available:string =  "";
    no_spots_are_available:string =  "";
    one_spot_is_available:string =  "";
    project_activity:string =  "";
    project_duration:string =  "";
    project_incentive:string =  "";
    project_language:string =  "";
    project_timeframe:string =  "";
    register_button_label:string =  "";
    review_all_application:string =  "";
    roche_person_reach_you:string =  "";
    study_sessions:string =  "";
    time_frame_image:string =  "";
    what_happen_after_you_apply:string =  "";
    your_voice_matters:string =  "";
    profile_image:string = "";
    profiles:string = "";
    static_default_image:string = "";
    about_the_co_creation:string = "";
    why_it_matters:string = "";
    what_you_will_get_by_joining:string = "";
    faq_title:string = "";
    footer_heading:string = "";
    review_all_application_descripti:string = "";
}


export class projectDetails{
    activity_schedule:string =  "";
    event_description:string =  "";
    event_image_url:string =  "";
    event_register_status:string =  "";
    expected_time_frame_for_the_acti:string =  "";
    incentive:string =  "";
    language:string =  "";
    nid:string =  "";
    participating_study:string =  "";
    session_duration:string =  "";
    title:string =  "";
    what_kind_of_activities_are_plan:string =  "";
    roles:string = "";
    faq:string = "";
    customer_benefit_2_image:string = "";
    customer_benefit_2:string = "";
    customer_benefit_1_image:string = "";
    customer_benefit_1:string = "";
    compensation_details:string = "";
    compensation_description:string = "";
    compensation_image:string = "";
    compensation_eligible:string = "";
    roche_signature:string = "";
    project_date:string = "";
    project_availability_for_country:string = "";
}

export class faq{
    questions:any = [];
    answers:any = []
}

interface faqs {
    question: string;
    answer: string;
}