import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { Observable } from 'rxjs/Observable';
import { AppService } from './../../../app.service';

@Component({
  selector: 'dl-open-project',
  templateUrl: './open-project.component.html',
  styleUrls: ['./open-project.component.scss'],
 // changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenProjectComponent implements OnInit {
  public projectList: Array<object>;
  public categoryName: Array<object>;
  public staticLabels: Array<object>;
  public projects: Array<object>;
  public limitPerRow = 3;
  // public filterBy: Array<string>;
  public selectFormControl = new FormControl();
  public filteredData: Array<object>;
  public openResponse: Observable<any>;
  public proPrototype: string;
  public filteredArray:any;
  public filteredProjectOptions:any;
  dataRenderFlag: boolean = false;
  dataRenderFlag2: boolean = false;
  dataRenderFlag3: boolean = false;
  public category_name_list:any;

  // public filterBy = {
  //   category_name_list:'',
  // }
  public constructor(private appService: AppService, private router: Router, private changeDetectorRef: ChangeDetectorRef, private readonly oneSnackBar?: OneSnackBarService) {}

  public ngOnInit(): void {
    const categoryApiUrl = '/api/category/list',
      staticLabelApi = '/api/open-projects/static-text',
      proOverviewApi = '/api/open-projects/overview',
      proStaticLableApi = '/api/open-project/label';

    this.appService.getProjectLabel(proStaticLableApi);
    this.appService.getApi(staticLabelApi).subscribe(data => {
      this.staticLabels = data[0];
      // if(this.projects['event_image'] == ""){
      //   this.projects['event_image'] = this.staticLabels['static_default_image'];
      // }
      this.dataRenderFlag = true;
      this.changeDetectorRef.detectChanges();
    });

    this.appService.getApi(categoryApiUrl).subscribe(data => {

        this.category_name_list = data[0];
        var fData = this.category_name_list.category_name_list;
        this.filteredProjectOptions = fData.split(',');
         this.dataRenderFlag2 = true;
    
         this.changeDetectorRef.detectChanges();
      // this.categoryName = data[0];
    //   this.filterBy = this.categoryName['category_name_list'].split(',');
    });

    this.openResponse = this.appService.getApi(proOverviewApi,null,"true");
    this.openResponse.subscribe(data => {
      this.filteredData = this.projectList = data;
      this.projects = data;
      this.dataRenderFlag3 = true;
      // if (this.projectList.length > 3) {
      //   this.projects = this.projectList.slice(0, this.limitPerRow);
      // } else {
      //   this.projects = data;
      // }
      this.changeDetectorRef.detectChanges();
    });
  }

  public labelClassBind(regStatus: string): string {
    return regStatus === 'Registered' ? 'project-status-reg' : 'project-status-other';
  }

  public getProtoType(regStatus: string): string {

    if (regStatus['event_register_status'] === 'Registered') {

     this.proPrototype = this.staticLabels['registered_button_value'];
      return this.proPrototype;
    } else {
 
      this.proPrototype = regStatus['activity_plan_value'];
      return this.proPrototype;
    }
  }

  public redirect(projectId: number): void {
    this.router.navigate(['/project'], { queryParams: { event_id: projectId } });
  }

  public filterProjectData(type: string) {
    let messagegApi = '/api/register-success-message';
    this.limitPerRow = 3;

    if (type.toLowerCase().trim() === this.staticLabels['reset_filter'].toLowerCase().trim()) {

      this.projects = this.projectList;
      this.filteredData = this.projects;
    } else {
      this.filteredData = this.projectList.filter(item => item['activity_plan_value'].toLowerCase().trim() === type.toLowerCase().trim());
    }
    if (this.filteredData.length > 0) {
      this.projects = this.filteredData;
    } 
    // else {
    //   this.appService.getApi(messagegApi).subscribe (response => {
    //     this.oneSnackBar.open(response[0].category_filter_massage, OneSnackBarType.WARNING, '', { duration: 3000 });
    //   });
    // }
  }

  // public showMore(): void {
  //   this.limitPerRow = this.limitPerRow + 3;
  //   this.projects = this.filteredData.slice(0, this.limitPerRow);
  // }

  // public showLess(): void {
  //   this.projects = this.filteredData.slice(0, 3);
  //   document.querySelector('.open-project').scrollIntoView();
  // }
}
