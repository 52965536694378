import { Inject, NgModule, OnInit } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { drupalEnv, environment } from '../environments/environment';
import { AppRouterModule } from './app-router.module';
import { AppComponent } from './app.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { CoreModule } from './core/core.module';
import { SampleFeatureModule } from './features/sample-feature/sample-feature.module';
import { ExperienceLabModule } from './features/experience-lab/experience-lab.module';
import { DOCUMENT } from '@angular/common';
import { ProjectModule } from './features/project/project.module';
import { RegistrationModule } from './features/registration/registration.module';
import { ENVIRONMENT } from './core/models/environment.token';
import { AskInfoModule } from './features/ask-info/ask-info.module';
import {OneExpandableModule} from '@one/angular-kit/layout';

// import { RegistrationFormComponent } from '../registration-form/registration-form.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRouterModule,
    HammerModule,
    ExperienceLabModule,
    ProjectModule,
    RegistrationModule,
    OneExpandableModule,
    CoreModule.forRoot(),
    SampleFeatureModule,
    AskInfoModule
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    ...environment.providers,
    { provide: ENVIRONMENT, useValue: drupalEnv },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(@Inject(DOCUMENT) private document: Document) {

    const isUserSignedIN = localStorage.getItem('amplify-redirected-from-hosted-ui');

    /**
     * Case 1:
     * If user not yet LoggedIn and hitting Apllication URL along with projectID as parameter directly
     * it will save the projectid from url in localstorage and after successful logIn user will redirect to specific project
     * page based on saved ProjectId
     */

    if (isUserSignedIN == null) {
      const projectIdWithParamter = this.document.location.search;
      const getProjectPageIdentifier = projectIdWithParamter.replace(/[^a-zA-Z]+/g, '');
      if (getProjectPageIdentifier == 'eventid') {
        const projectId = projectIdWithParamter.replace(/^\D+/g, '');
        localStorage.setItem('comingDirectFromSSOLogin', projectId);
      }
    }

    /**
     * Case2:
     * If user is LoggedIn and hitting Apllication URL along with projectID as parameter directly
     * it will save the projectid from url in localstorage and redirect to specific project
     * page based on saved ProjectId
     */
    // If isUserSignedIN == null it means user is not signedin through SSO
    if (isUserSignedIN != null) {
      const projectIdWithParamter = this.document.location.search;
      const getProjectPageIdentifier = projectIdWithParamter.replace(/[^a-zA-Z]+/g, '');
      if (getProjectPageIdentifier == 'eventid') {
        const projectId = projectIdWithParamter.replace(/^\D+/g, '');
        localStorage.setItem('afterSuccessfullSSOLogin', projectId);
      }
    }
  }
}
