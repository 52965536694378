
 <div *ngIf = "dataRenderFlag">
 <section class="banner">
    <mat-card>
      <mat-card-content>
        <div class="banner-title-section">
          <mat-card-title>{{bannerData.title}}
          </mat-card-title>
          <mat-card-subtitle [innerHTML]="bannerData?.co_creation_banner_description">
          </mat-card-subtitle>
          </div>
      </mat-card-content>
      <img mat-card-image [src]="bannerData?.co_creation_banner_image" alt="banner">
    </mat-card>
  </section>
</div>
