
  <div class="headerCustomss">
    <!-- <h1 class="mat-title dialog-mobile-title" *ngIf="headerTitle">
      {{headerTitle?.title}}
    </h1> -->
    
    <div class=" logo">
      <div >
      <!-- <a [routerLink]="['/app-enabler']" [queryParams]="{props: 'appLogo'}" > -->
        <img alt="DiaLog logo" class="app-logo-custom" [src]="headerTitle?.header_logo_1"/>
      <span class="header-title col " [innerHTML] = "headerTitle?.header_title"></span>
    </div>
    </div>

    <div class="select-language">
    <button [matMenuTriggerFor]="menuLeft" one-icon-button mat-icon-button disableRipple>
      <mat-icon>globe</mat-icon> {{changedName}}
    </button>
    <mat-menu #menuLeft="matMenu" xPosition="before" oneMenu>
      <span class="change-lang-text">{{ 'Change Language' }}</span>
      <button *ngFor="let lang of displayedLanguages" mat-menu-item (click)="setLanguage(lang)">
        <span>{{ lang.label }}</span>
      </button>
    </mat-menu>
  </div>

  </div>