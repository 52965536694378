import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../app.service';

@Component({
  selector: 'dl-afterregistration',
  templateUrl: './afterregistration.component.html',
  styleUrls: ['./afterregistration.component.scss']
})
export class AfterregistrationComponent implements OnInit, OnDestroy {
  public event_id: any;
  public projectTitle:any;
  public formTahnksData: any;
  public constructor(private appService: AppService,private renderer: Renderer2, private router: Router, private route: ActivatedRoute) {
    this.renderer.addClass(document.body, 'local-notification-config');
  }
  public ngOnDestroy() {
    this.renderer.removeClass(document.body, 'local-notification-config');
  }

  ngOnInit(): void {
    this.event_id = this.route.snapshot.queryParamMap.get('event_id');

    const apiUrl  =  '/api/register/label'

 this.appService.getApi(apiUrl).subscribe(data => {
    if(data)
      this.formTahnksData = data[0];
    });
  }
  public closeRegistrationForm(): void {
    this.router.navigate(['/project'], { queryParams: { event_id: this.event_id } });
  }
}
